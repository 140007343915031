'use client';

import { ReactNode } from 'react';
// import { useScreenStore } from '@eppay/stores/screen';
import { Box, ClientGate, EpisodeThemeProvider } from '@episode-ui';
import { datadogRum } from '@datadog/browser-rum';

const PageClientLayout = ({ children }: { children: ReactNode }) => {
  datadogRum.init({
    applicationId: 'dfbb758b-8621-4e18-bf6b-aedd567d32b9',
    clientToken: 'pubc0b60d782af3cadcaac3003158310965',
    site: 'datadoghq.com',
    allowedTracingUrls: ['https://sapi.epsd.co.kr', 'https://eppay.epsd.co.kr'],
    service: 'eppay-production',
    env: 'development',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackResources: true,
    trackLongTasks: true,
    trackUserInteractions: true,
    defaultPrivacyLevel: 'mask-user-input',
  });
  datadogRum.startSessionReplayRecording();

  return (
    <>
      <ClientGate>
        <EpisodeThemeProvider>
          <Box sx={{ position: 'relative', backgroundColor: 'white', padding: 0, margin: 0 }}>{children}</Box>
        </EpisodeThemeProvider>
      </ClientGate>
    </>
  );
};

export default PageClientLayout;
